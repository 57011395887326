import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPreviousAttempt } from '../service/question/action';
import { CircularProgress } from '@material-ui/core';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullPortion: [],
            chapterWise: []
        }
    }

    componentDidMount() {
        let sessionData = localStorage.getItem('session');
        sessionData = JSON.parse(sessionData);
        this.props.dispatch(getPreviousAttempt(sessionData.ID))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_isEqual(this.props.previousData, prevProps.previousData)) {
            let chapterWise = _filter(this.props.previousData, data => {
                if (data.EXAM_TYPE === 'C') {
                    return true;
                }
            })
            let fullPortion = _filter(this.props.previousData, data => {
                if (data.EXAM_TYPE === 'F') {
                    return true;
                }
            })
            this.setState({ chapterWise: chapterWise, fullPortion: fullPortion })
        }
    }

    render() {
        const { historyLoading } = this.props;
        const { chapterWise, fullPortion } = this.state;

        return (
            <div>
                {historyLoading &&
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </div>
                }
                {/* <Card> */}
                <div className="flexRow">
                    <div style={{ width: '50%', fontSize: 13 }}>
                        <div style={{ textAlign: 'center', fontSize: 15, fontWeight: 'bold' }}>Full Portion Previous Attempts</div>
                        <br/>
                        <div className="flexRowWrap  flexRowJustifyCenter">
                            {_map(fullPortion, (data, key) => {
                                return (
                                    <div key={key}>
                                        <Card style={{ margin: '2%', backgroundColor: '#3f51b5' }} >
                                            <CardContent>
                                                <div>
                                                    <div style={{color: 'white'}}>Exam Date : {moment(data.EXAM_DATE).format('MM-DD-YYYY hh:ss')} </div>
                                                    <div style={{color: 'white'}}>Marks Obtained : {data.MARKS ? data.MARKS : 0} </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ width: '50%', fontSize: 13 }}>
                        <div style={{ textAlign: 'center', fontSize: 15, fontWeight: 'bold' }}>Chapter Wise Previous Attempts</div>
                        <br/>
                        <div className="flexRowWrap  flexRowJustifyCenter">
                            {_map(chapterWise, (data, key) => {
                                console.log('data', data.EXAM_DATE)
                                return (
                                    <div key={key}>
                                        <Card style={{ margin: '2%', backgroundColor: '#3f51b5' }} >

                                            <CardContent>
                                                <div>
                                                    <div style={{color: 'white'}}>Exam Date : {moment(data.EXAM_DATE).format('MM-DD-YYYY hh:ss')} </div>
                                                    <div style={{color: 'white'}}>Marks Obtained : {data.MARKS ? data.MARKS : 0} </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                {/* </Card> */}
            </div>
        );
    }
}

const mapStateToProps = (state, ownprops) => {
    return {
        historyLoading: state.chapterReducer.historyLoading,
        previousData: state.chapterReducer.previousData
    }
}

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(History)