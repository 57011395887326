import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectQuestion,saveAnswer } from '../service/question/action';

class QuestionCrumbs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastIndex: 0
        }
    }

    updateIndex = () => this.setState({lastIndex:this.props.selectedQuestion},() => this.props.saveAnswer(this.props.selectedQuestion));

    render() {
        const { selectQuestion, selectedQuestion, answers, questions } = this.props;
        return (
            <div>
                <h6 style={{ margin: 10 }}>Chemistry</h6>
                <div className="flexRowWrap">
                    {
                       questions ?  questions.map((item, index) => {
                            let isAnswered = answers[item.ID] && answers[item.ID].isSaved && answers[item.ID].option;
                            let isCurrent = selectedQuestion === index;
                            let color = isCurrent ? '#46cce8' : (isAnswered ? '#8cba51' : 'white');
                            return <Crumb key={index} updateIndex={this.updateIndex} index={index} value={index + 1} color={color} selectQuestion={selectQuestion} />
                        }) :null
                    }
                </div>

            </div>
        )
    }
}

const Crumb = (props) => {
    return <span
        style={{
           // backgroundColor: props.current ? '#46cce8' : 'white',
           backgroundColor: props.color,
            padding: '2%',
            borderRadius: 3,
            border: '1px solid #ccc',
            margin: 5,
            width: '2.8vw',
            cursor: 'pointer',
            textAlign: 'center'
        }}
        onClick={() => {
            props.updateIndex();
            props.selectQuestion(props.index);
        }}
    >
        {`Q${props.value}`}
    </span>
}

// const Crumb = (props) => <Button variant="contained" color="primary" style={{margin:5, padding:0}}>{`Q${props.value}`}</Button>

const mapStateToProps = (state) => {
    return {
        selectedQuestion: state.chapterReducer.selectedQuestion, 
        answers: state.chapterReducer.answers,
        questions: state.chapterReducer.charterQuestion
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectQuestion: (id) => dispatch(selectQuestion(id)),
        saveAnswer: (index) => dispatch(saveAnswer(index))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(QuestionCrumbs);