const createRequestTypes = base => {
	const res = {};
	['REQUEST', 'SUCCESS', 'FAILED'].forEach(type => { res[type] = `${base}_${type}`; });
	return res;
}

export const GET_CHAPTER_QUESTION = createRequestTypes('GET_CHAPTER_QUESTION')

export const SELECT_QUESTION = 'SELECT_QUESTION'

export const NEXT_QUESTION = 'NEXT_QUESTION'

export const PREVIOUS_QUESTION = 'PREVIOUS_QUESTION'

export const ADD_ANSWER = 'ADD_ANSWER'

export const SAVE_ANSWER = 'SAVE_ANSWER'

export const CLEAR_ANSWER = 'CLEAR_ANSWER'

export const DESELECT = 'DESELECT'

export const SET_EXAM_TYPE = 'SET_EXAM_TYPE'

export const SET_DEFAULT_DASHBOARD = 'SET_DEFAULT_DASHBOARD'

export const POST_ANSWERS = createRequestTypes('POST_ANSWERS')

export const AUTHENTICATE = createRequestTypes('AUTHENTICATE')

export const LOGOUT = createRequestTypes('LOGOUT')

export const GET_CHAPTER = createRequestTypes('GET_CHAPTER')

export const GET_PREVIOUS_ATTEMPT = createRequestTypes('GET_PREVIOUS_ATTEMPT')

export const CLEAR_STORE_EXCEPT_SESSION = 'CLEAR_STORE_EXCEPT_SESSION';