export const initial_state = {
    error: false,
    charterQuestion: [],
    msg: '',
    testResultMark: 0,
    selectedQuestion: 0,
    answers: {},
    questionsLoading: false,
    postingInProgress: false,
    postAnswersRes: [],
    sessionLoading: false,
    sessionDetails: {},
    chapters: [],
    examType: '',
    examTime: 0,
    chapterId: null,
    attempt: null,
    historyLoading: false,
    previousData: [],
    isQuestion: 0,
    setToDefault: 0
}