import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
// import MainApp from './MainApp';
import todoApp from '../src/store/rootReducer';
import * as serviceWorker from './serviceWorker';
import App from './App';


const store = createStore(todoApp, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <App dispatch={store.dispatch}/>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
