import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import _find from 'lodash/find';
import '../App.css';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Question from '../components/Question';
import Header from '../components/Header';
import TitleBar from '../components/TitleBar';
import QuestionCrumbs from '../components/QuestionCrumbs';
import Chip from '@material-ui/core/Chip';
import {
	chapterQuestion, selectQuestion, nextQuestion, prevQuestion, deSelect,
	saveAnswer, postAnswer, getChapters, setExamType, decrementPendingAttempt
} from '../service/question/action';
import Loader from 'react-loader-spinner';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import { CircularProgress } from '@material-ui/core';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 240,
	},
}));

const devMode = localStorage.getItem('devMode');
class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 0, //step-0 portion step-1 instruction step-2 Questions
			charterQuestions: [],
			currentQuestion: 0,
			chapterChoosen: false,
			selectedPortion: null,
			selectedChapter: null,
			noQuestions: false,
			submitLoading: false
		}
	}

	componentDidMount() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			localStorage.setItem('devMode', true);
		} else {
			localStorage.removeItem('devMode');
		}
		this.props.getChapters();
	}


	updateStep = (step, obj) => {
		console.log('updateStep', step, obj);
		if (step === 1) {
			if (obj.isQuestion === 0) {
				this.setState({ selectedPortion: obj.type, noQuestions: true });
			} else {
				this.setState({ selectedPortion: obj.type, step, noQuestions: false });
			}
			this.props.setExamType({ type: obj.type, chapterId: obj.id, attempt: obj.attempt, isQuestion: obj.isQuestion ? obj.isQuestion : 0 });
		} else {
			this.setState({ step, noQuestions: false });
		}
	}

	changeQuestion = (operation) => {
		let sessionData = JSON.parse(localStorage.getItem('session'));
		const { selectedQuestion, charterQuestions, sessionDetails, attempt, examType, chapterId } = this.props;
		let availSession = sessionData || sessionDetails;
		if (operation === 'next' && (selectedQuestion !== charterQuestions.length - 1)) {
			this.props.nextQuestion();
		} else if (operation === 'prev' && (selectedQuestion !== 0)) {
			this.props.prevQuestion();
		} else if (selectedQuestion === (charterQuestions.length - 1)) {
			this.setState({ submitLoading: true })
			setTimeout(() => {
				const { answers } = this.props;
				let questionIDS = [];
				let answersArr = [];
				Object.keys(answers).forEach(ques_id => {

					if (answers[ques_id].option && answers[ques_id].isSaved) {
						questionIDS.push(ques_id);
						answersArr.push({
							question_id: ques_id,
							option_id: answers[ques_id].option.ID
						})
					}
				});
				charterQuestions.map(question => {
					if(!questionIDS.includes(question.ID)) {
						answersArr.push({question_id:String(question.ID), option_id:0});
					}
				});
				this.props.postAnswer(answersArr, availSession.ID, examType, chapterId, examType === 'F' ? (availSession.FULLPORTIONATTEMPTS - attempt) : (availSession.COMPLETEDATTEMPTSFORCHAPTERS + 1));
				this.props.decrementPendingAttempt(availSession, examType, chapterId);
			}, 1000);
		}
	}

	saveAnswer = (operation, index) => {
		this.props.saveAnswer(index);
		this.changeQuestion(operation);
	}

	componentDidUpdate(prevProps, prevState) {
		const { selectedPortion, step } = this.state;
		const { postingInProgress, chapterId,charterQuestions } = this.props;
		if ((prevProps.chapterId !== chapterId)) {
			if (this.props.isQuestion === 1) {
				this.props.getChapterQuestion(selectedPortion, chapterId);
				this.props.selectQuestion(0);
			}
		}
		if ((prevState.selectedPortion !== selectedPortion)) {
			this.props.getChapterQuestion(selectedPortion, chapterId);
			this.props.selectQuestion(0);
		}
		if ((prevProps.postingInProgress !== postingInProgress) && !postingInProgress) {
			this.props.history.push('/results');
		}
		if (!_isEqual(this.props.setToDefault, prevProps.setToDefault)) {
			this.setState({ step: 0, noQuestions: false })
		}
		if((charterQuestions.length ==0 && prevProps.charterQuestions.length > 0) && (step == 2)) {
			console.log('inside ques condition', prevProps.charterQuestions,  charterQuestions);
			this.setState({selectedPortion:null},() => this.updateStep(0));
		}
	}

	render() {
		const { step } = this.state;
		const {charterQuestions, selectedQuestion, loading, chapters, sessionDetails, examType, chapterId, examTime, chapterLoading} = this.props;
		if (this.state.noQuestions) {
			return <div style={{ textAlign: 'center' }}>
				<h5>No Questions are available. Please select another test.</h5>
				<center><Button variant="contained" color="secondary" onClick={() => this.updateStep(0)} style={{ marginTop: 10 }}>BACK</Button></center>
			</div>
		}
		const examInfo = examType === 'F' ? `Full Portion` : `Chapter - ${(_find(chapters, item => item.ID === chapterId) || {}).NAME}`
		if (step === 0) {
			return <Portion updateStep={this.updateStep} chapters={chapters} session={sessionDetails} loading={chapterLoading}/>
		} else if (step === 1) {
			return <Instructions updateStep={this.updateStep} examInfo={examInfo} examTime={examTime} />
		}
		if (loading) {
			return <div className="flexRowJustifyCenter flexRowAlignCenter" style={{ width: '100%', minHeight: '50vh' }}>
				<Loader type="TailSpin" color="#46cce8" height={80} width={80} visible={true} style={{ margin: 'auto' }} />
			</div>
		}

		return (
			<div style={{ padding: '5%' }}>
				{/* <Timer /> */}
				<Header />
				<TitleBar history={this.props.history} chip={examInfo} />
				<div>
					<div className="flexRow">
						<div className="flexRow" style={{ flex: 8, padding: 20, maxWidth: '83%', overflowX: 'auto' }}>
							<h4 style={{ marginRight: 20 }}>{`Q${selectedQuestion + 1}`}</h4>
							<div >
								<Question setClick={click => this.clickChild = click}
									currentQuestion={selectedQuestion} surveyQuestion={charterQuestions[selectedQuestion]} />

							</div>
						</div>
						<div style={{ flex: 2 }}>
							<QuestionCrumbs />
						</div>
					</div>
					<div className="flexRow" style={{ marginTop: 20 }}>
						<div style={{ flex: 8 }}>
							<Button variant="contained" color="primary" onClick={() => this.clickChild()}>
								DESELECT
							</Button>
						</div>
						<Button disabled={selectedQuestion === 0} variant="contained" className="mr-3" color="primary" onClick={() => this.changeQuestion('prev')}>
							BACK
						</Button>
						<Button variant="contained" className="mr-3" color="secondary" onClick={() => this.changeQuestion('next')}>
							SKIP
						</Button>

						{this.state.submitLoading ?
							<div style={{ textAlign: 'center' }}>
								<CircularProgress />
							</div>
							:
							<Button variant="contained" color="primary" onClick={() => this.saveAnswer('next', selectedQuestion)}>
								{(selectedQuestion === charterQuestions.length - 1) ? `SUBMIT` : `NEXT`}
							</Button>
						}
					</div>
				</div>
			</div>
		);
	}
}


const Portion = (props) => {
	let sessionData = JSON.parse(localStorage.getItem('session'));
	const { chapters, session, loading } = props;
	const classes = useStyles();
	let availSession = sessionData || session;
	const getAttemptDetails = data => {
		let currentAttempt = _filter(availSession.PENDINGATTEMPTSFORCHAPTERS, item => {
			if (parseInt(item.CHAPTERID) === data) {
				return true;
			}
		});

		_filter(availSession.PENDINGATTEMPTSFORCHAPTERS, item => item.PENDING_ATTEMPT > 0);
		let returnData = '';
		if (currentAttempt.length) {
			if (currentAttempt[0].PENDING_ATTEMPT === 0) {
				returnData = `Attempts Exceeded`;
			} else {
				returnData = `Attempts: ${currentAttempt[0].PENDING_ATTEMPT}/${availSession.CHAPTERWISEATTEMPTS}`;
			}
		} else {
			returnData = `Attempts: ${availSession.CHAPTERWISEATTEMPTS}/${availSession.CHAPTERWISEATTEMPTS}`;
		}
		return returnData;
	}
	let fullPortionRemText = availSession.PENDINGATTEMPTSFORFULLPORTION > 0 ? `Attempts Remaining: ${availSession.PENDINGATTEMPTSFORFULLPORTION} / ${availSession.FULLPORTIONATTEMPTS}` : `Attempts Exceeded`;
	let chapterPortionRemText = availSession.COMPLETEDATTEMPTSFORCHAPTERS < availSession.CHAPTERWISEATTEMPTS ? `Attempts Remaining: ${availSession.CHAPTERWISEATTEMPTS - availSession.COMPLETEDATTEMPTSFORCHAPTERS} / ${availSession.CHAPTERWISEATTEMPTS}`
		: `Attempts Exceeded`;
	let chaptersGroupedBy = {firstYear:chapters.filter(chapter => chapter.YEAR == "1"), secondYear: chapters.filter(chapter => chapter.YEAR == "2")};
	return <div className={classes.root}>
		<Grid container spacing={3}><Grid item xs={6}>
			<Paper className={classes.paper}>
				<Grid item xs={12}>
					<center>
						<h3>Full Portion Exam</h3>
					</center>
				</Grid>
				<center><h5>{fullPortionRemText}</h5></center>
			
				<Button 
				// disabled={!JSON.parse(devMode) && fullPortionRemText === `Attempts Exceeded`} 
				disabled={fullPortionRemText === `Attempts Exceeded`} 

				variant="contained" color="primary" style={{ marginTop: 30 }} onClick={() => props.updateStep(1, { type: 'F', attempt: availSession.PENDINGATTEMPTSFORFULLPORTION })}>
					Go to Exam
  				</Button>
			</Paper>
		</Grid>
			<Grid item xs={6}>
				<Paper className={classes.paper}>
					<Grid item xs={12}>
						<center>
							<h3>Chapter Wise Exam</h3>
						</center>
						<center>
							<h5>{chapterPortionRemText}</h5>
						</center>
					</Grid>
					<ExpansionPanel style={{backgroundColor:'#a6b1e1'}}>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							First Year
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							{renderChapterList(chaptersGroupedBy.firstYear, props.updateStep, availSession, chapterPortionRemText, loading)}
						</ExpansionPanelDetails>
					</ExpansionPanel>
					<ExpansionPanel  style={{backgroundColor:'#a6b1e1', marginTop:20}}>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel2a-content"
							id="panel2a-header"
						>
							Second Year
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							{renderChapterList(chaptersGroupedBy.secondYear, props.updateStep, availSession, chapterPortionRemText, loading)}          
						</ExpansionPanelDetails>
					</ExpansionPanel>
					{/* <center><h5>Attempts Remaining: {sessionData.CHAPTERWISEATTEMPTS}</h5></center> */}
				</Paper>
			</Grid>
		</Grid></div>
}


const renderChapterList = (chapters, updateStep, availSession, chapterPortionRemText, loading) => {
	if (loading) {
		return <div className="flexRowJustifyCenter flexRowAlignCenter" style={{ width: '100%' }}>
			<Loader type="TailSpin" color="#ffffff" height={30} width={30} visible={true} style={{ margin: 'auto' }} />
		</div>
	} else {
		return <div className="flexColumnJustifyCenter" style={{ marginTop: 30 }}>
		{chapters.map((chapter, key) => {
			const chapterAttemptPending = (_find(availSession.PENDINGATTEMPTSFORCHAPTERS, data => data.CHAPTERID === chapter.ID) || {}).PENDING_ATTEMPT;
			
			return (<div style={{ textAlign: 'center' }} key={key}>
				{/* <div>{getAttemptDetails(chapter.ID)}</div> */}
				<Button variant="contained" color="primary"
					disabled={chapterPortionRemText === 'Attempts Exceeded'}
					// disabled={getAttemptDetails(chapter.ID) === 'Attempts Exceeded'}
					onClick={() => updateStep(1, { isQuestion: chapter.ISQUESTION, type: 'C', id: chapter.ID, attempt: chapterAttemptPending || availSession.CHAPTERWISEATTEMPTS })}
					style={{ marginBottom: 15, marginLeft: 15, marginRight: 15 }}>
					{`${chapter.NAME}`}
				</Button>
			</div>)
		})}
		</div>
	}
}



const Instructions = (props) => {
	// const classes = useStyles();
	const { examInfo, examTime } = props;
	return <div>
		<center><h3>Instructions</h3></center>
		<center>
			<Chip
				variant="outlined"
				size="medium"
				label={examInfo}
				clickable
				color="primary"
			/></center>
		<ul>
			<li>The exam duration is <span style={{ color: 'red' }}>{Math.round(examTime / 60)}  mins.</span></li>
			<li>Your answers will be submitted once the timer is over or you submit the exam manually.</li>
			<li>Please <span style={{ color: 'red' }}>do not refresh</span> the browser without completing the exam</li>
			<li>Marks will be displayed once the exam is over</li>
			<li>A countdown timer will be displayed on top of the questions. </li>
			<li>The timer will turn red during last 5 mins. </li>
		</ul>
		<div className="flexRowJustifyBetween" style={{ margin: 30 }}>
			<Button variant="contained" color="secondary" onClick={() => props.updateStep(0)}>BACK</Button>
			<Button variant="contained" color="primary" onClick={() => props.updateStep(2)}>
				Attend
		  </Button>


		</div>
	</div>
}

const mapStateToProps = (state) => {
	const { charterQuestion, selectedQuestion, questionsLoading, answers, chapters,
		sessionDetails, postingInProgress, examTime, examType, attempt, chapterId,
		isQuestion, setToDefault,chapterLoading } = state.chapterReducer;
	return {
		charterQuestions: charterQuestion,
		selectedQuestion: selectedQuestion,
		loading: questionsLoading,
		answers: answers,
		chapters,
		sessionDetails: sessionDetails,
		postingInProgress,
		examType,
		attempt,
		chapterId,
		examTime,
		isQuestion,
		setToDefault,
		chapterLoading
	};
};


const mapDispatchToProps = dispatch => {
	return {
		getChapterQuestion: (type, chapterId) => dispatch(chapterQuestion(type, chapterId)),
		selectQuestion: (id) => dispatch(selectQuestion(id)),
		nextQuestion: () => dispatch(nextQuestion()),
		prevQuestion: () => dispatch(prevQuestion()),
		deSelect: (id) => dispatch(deSelect(id)),
		saveAnswer: (id) => dispatch(saveAnswer(id)),
		postAnswer: (answers, id, type, chapterId, attempt) => dispatch(postAnswer(answers, id, type, chapterId, attempt)),
		getChapters: () => dispatch(getChapters()),
		setExamType: (obj) => dispatch(setExamType(obj)),
		decrementPendingAttempt: (obj, type, id) => dispatch(decrementPendingAttempt(obj, type, id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

