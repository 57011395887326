import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import SVGPieTimer from '../components/SVGPieTimer';
import { connect } from 'react-redux';
import { postAnswer, decrementPendingAttempt  } from '../service/question/action';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

// const EXAM_TIME = 1200;
const CRUCIAL_TIME = 300;

class TitleBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: {
                hours: '00',
                minutes: '00',
                seconds: '00'
            },
            remainingTime: props.examTime,
            crucialTime: false
        }
    }

    handleEndTest = () => {
		let sessionData = JSON.parse(localStorage.getItem('session'));

        const {charterQuestion, answers, examType, chapterId, attempt, sessionDetails } = this.props;
        let answersArr = [];
        let questionIDS = [];
		let availSession = sessionData || sessionDetails;

        Object.keys(answers).forEach(ques_id => {
            if (answers[ques_id].option && answers[ques_id].isSaved) {
                questionIDS.push(ques_id);
                answersArr.push({
                    question_id: ques_id,
                    option_id: answers[ques_id].option.ID
                })
            }
        });
        charterQuestion.map(question => {
            if(!questionIDS.includes(question.ID)) {
                answersArr.push({question_id:String(question.ID), option_id:0});
            }
        });
        this.props.dispatch(postAnswer(answersArr, availSession.ID, examType, chapterId, examType === 'F' ? (availSession.FULLPORTIONATTEMPTS - attempt) : (availSession.COMPLETEDATTEMPTSFORCHAPTERS + 1)));
        this.props.dispatch(decrementPendingAttempt(availSession, examType, chapterId));
    
        this.props.history.push('/results');
    }

    timer = () => {
        const { remainingTime } = this.state;
        let hours = Math.floor(remainingTime / 3600);
        let minutes = String(Math.floor((remainingTime / ((hours ? hours : 1) * 60)) % 60)).padStart(2, '0');
        let seconds = String(remainingTime % 60).padStart(2, '0');
        hours = String(hours).padStart(2, '0');
        this.setState({ timer: { hours, minutes, seconds }, remainingTime: remainingTime - 1 });
        if (remainingTime === CRUCIAL_TIME) {
            this.setState({ crucialTime: true })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.remainingTime === 0) {
            clearInterval(this.counter);
            this.handleEndTest();
            // this.props.history.push('/results');
        }
        if (!_isEqual(this.props.charterQuestion, prevProps.charterQuestion) && !_isEmpty(this.props.charterQuestion)) {
            this.counter = setInterval(this.timer, 1000);
        }
    }

    componentDidMount() {
        if (!this.props.chaptersLoading) {
            this.counter = setInterval(this.timer, 1000);
        }
    }



    render() {
        const { timer, remainingTime } = this.state;
        const { chip } = this.props;
        return (
            <div className="flexRowAlignCenter" style={{ backgroundColor: '#cfe7ec' }}>
                <div className="flexRowJustifyBetween flexRowAlignCenter" style={{ flex: 5, padding: '0px 50px 0px 50px' }}>
                    <div>
                        <Chip
                            size="medium"
                            label={chip}
                            clickable
                            color="primary"
                            deleteIcon={<DoneIcon />}
                        />
                    </div>
                    <div style={{
                        color: 'white', backgroundColor: '#ff722d',
                        padding: '10px 25px 10px 25px', borderRadius: 5, cursor: 'pointer'
                    }}
                        onClick={() => this.handleEndTest()}
                    >
                        <span style={{ fontSize: 15, fontWeight: 'bold' }}>END TEST</span>
                    </div>
                </div>
                <div className="flexRowAlignCenter" style={{ flex: 1, textAlign: 'center' }}>
                    <SVGPieTimer
                        height={50}
                        width={50}
                        duration={remainingTime * 1000}
                        loops={1}
                        reverse={false}
                        inverse={true}
                    />
                    <div>
                        <h6 style={this.state.crucialTime ? { color: 'red' } : {}}>Time Left</h6>
                        <h2 style={this.state.crucialTime ? { color: 'red' } : {}}>{`${timer.hours}:${timer.minutes}:${timer.seconds}`}</h2>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        charterQuestion: state.chapterReducer.charterQuestion,
        answers: state.chapterReducer.answers,
        examType: state.chapterReducer.examType,
        examTime: state.chapterReducer.examTime,
        chaptersLoading: state.chapterReducer.questionsLoading,
        chapterId: state.chapterReducer.chapterId,
        attempt: state.chapterReducer.attempt,
		sessionDetails: state.chapterReducer.sessionDetails,

    }
}

const mapDispatchToProps = dispatch => ({ dispatch });

const enhance = connect(mapStateToProps, mapDispatchToProps)(TitleBar)

export default withRouter(enhance);