import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Dashboard from './screens/Dashboard';
import Results from './screens/Results';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Login from './screens/Login';
import IdleTimer from 'react-idle-timer';

function App(compProps) {
	const protectedRoute = (RouteComponent, props) => localStorage.getItem('session') ? <RouteComponent {...props} /> : <Redirect to={{ pathname: '/' }} />
	return (
		<div>
							<IdleTimer
					element={document}
					onActive={() =>{}}
					onIdle={() => {if(window.location.pathname !== '/'){window.alert('Your session has expired. Please log back in.');window.location.href="/";}}}
					onAction={() =>{}}
					debounce={500}
					timeout={1000*30*60} />
<Router>
			<Route path="/history" render={(props) => <Dashboard isHistory={true} {...props} dispatch={compProps.dispatch} />} />
			<Route path="/dashboard" render={(props) => protectedRoute(Dashboard, {...props, dispatch:compProps.dispatch}) } />
			<Route exact path="/" render={(props) => <Login {...props} />} />
			<Route path="/results" render={props => protectedRoute(Results, {...props,dispatch:compProps.dispatch})} />
		</Router>
		</div>
		
	);
}



export default App;
