import * as types from './actionTypes';
import { initial_state } from '../initialState';

const chapterReducer = (state = initial_state, action) => {
    switch (action.type) {
        case types.GET_CHAPTER_QUESTION.REQUEST:
            return {
                ...state,
                charterQuestion: [],
                error: false,
                questionsLoading: true
            }
        case types.GET_CHAPTER_QUESTION.SUCCESS:
            return {
                ...state,
                charterQuestion: action.payload.data.Questions,
                error: false,
                questionsLoading: false
            }
        case types.GET_CHAPTER_QUESTION.FAILED:
            return {
                ...state,
                msg: action.payload.msg,
                error: true,
                questionsLoading: false
            }
        case types.SELECT_QUESTION:
            return {
                ...state,
                selectedQuestion: action.payload.id
            }
        case types.NEXT_QUESTION:
            return {
                ...state,
                selectedQuestion: state.selectedQuestion + 1
            }
        case types.PREVIOUS_QUESTION:
            return {
                ...state,
                selectedQuestion: state.selectedQuestion - 1
            }
        case types.ADD_ANSWER:
            const { answer } = action.payload
            return {
                ...state,
                answers: { ...state.answers, [answer.id]: { option: answer.option, isSaved: false } }
            }
        case types.SAVE_ANSWER:
            const { index } = action.payload;
            return {
                ...state,
                answers: {
                    ...state.answers,
                    [state.charterQuestion[index].ID]: {
                        ...state.answers[state.charterQuestion[index].ID],
                        isSaved: true
                    }
                }
            }
        case types.CLEAR_ANSWER: {
            return {
                ...state,
                answers: {}
            }
        }
        case types.DESELECT:
            let answersClone = Object.assign({}, state.answers);
            delete answersClone[state.charterQuestion[state.selectedQuestion].id];
            return {
                ...state,
                answers: answersClone
            }
        case types.POST_ANSWERS.REQUEST:
            return {
                ...state,
                postingInProgress: true
            }
        case types.POST_ANSWERS.SUCCESS:
            let removeSkip = state.answers;
            Object.keys(removeSkip).forEach(data => {
                if (!removeSkip[data].option || !removeSkip[data].isSaved) {
                    delete removeSkip[data]
                }
            });
            return {
                ...state,
                testResultMark: action.payload.testResultMark,
                postingInProgress: false,
                postAnswersRes: action.payload.data,
                answers: removeSkip
            }
        case types.POST_ANSWERS.FAILURE:
            return {
                ...state,
                postingInProgress: false
            }
        case types.AUTHENTICATE.REQUEST:
            return {
                ...state,
                sessionLoading: true
            }
        case types.AUTHENTICATE.SUCCESS:
            return {
                ...state,
                sessionLoading: false,
                sessionDetails: action.payload
            }
        case types.AUTHENTICATE.FAILED:
            return {
                ...state,
                sessionLoading: false
            }
        case types.LOGOUT:
            return {
                ...state,
                sessionDetails: {}
            }
        case types.GET_CHAPTER.REQUEST:
            return {
                ...state,
                chapterLoading: true
            }
        case types.GET_CHAPTER.SUCCESS:
            return {
                ...state,
                chapterLoading: false,
                chapters: action.payload.data.Chapters
            }
        case types.GET_CHAPTER.FAILED:
            return {
                ...state,
                chapterLoading: false
            }
        case types.SET_EXAM_TYPE:
            return {
                ...state,
                examType: action.payload.type,
                chapterId: action.payload.chapterId,
                attempt: action.payload.attempt,
                examTime: action.payload.type === 'F' ? 2700 : 1200,
                isQuestion: action.payload.isQuestion
            }
        case types.GET_PREVIOUS_ATTEMPT.REQUEST:
            return {
                ...state,
                historyLoading: true,
                previousData: []
            }
        case types.GET_PREVIOUS_ATTEMPT.SUCCESS:
            return {
                ...state,
                historyLoading: false,
                previousData: action.payload.previousList
            }
        case types.GET_PREVIOUS_ATTEMPT.FAILED:
            return {
                ...state,
                historyLoading: false,
                previousData: []
            }
        case types.SET_DEFAULT_DASHBOARD:
            return {
                ...state,
                setToDefault: action.payload
            }
        case types.CLEAR_STORE_EXCEPT_SESSION:
            return {
                ...initial_state,
                sessionDetails:state.sessionDetails
            }
        default:
            return state
    }
}

export default chapterReducer
