import axios from 'axios';
import * as types from '../question/actionTypes';

const BASE_URL = 'https://questions.tsrchemistry.com/models/api.cfc'
// const BASE_URL1 = 'https://api.entuite.com/models/testapi.cfc'

const appendHeaders = () => {
    const headers = { headers: { "authorization": "Basic cmFodWxAbWFpbC5jb206OTk5OTk5OTk5OQ==" } };
    // return headers;
}

export const chapterQuestion = (type, chapterid) => {
    const urlParams = type === "F" ? { method: 'getQuestions' } : { method: 'getQuestions', chapterid: chapterid }
    return dispatch => {
        dispatch({ type: types.GET_CHAPTER_QUESTION.REQUEST });
        axios
            .get(`${BASE_URL}`, { params: urlParams ,
            //      headers: {  
            //     "Access-Control-Allow-Origin": "*",
            //     "authorization": "Basic cmFodWxAbWFpbC5jb206OTk5OTk5OTk5OQ=="
            //   }},
            },
            
            )
            .then(res => {
                dispatch({
                    type: types.GET_CHAPTER_QUESTION.SUCCESS, payload: {
                        data: res.data,
                        msg: 'get surevey questions success'
                    }
                });
            })
            .catch(err => {
                dispatch({ type: types.GET_CHAPTER_QUESTION.FAILED, payload: { err } });
            });
    };
};


export const getChapters = () => {
    return dispatch => {
        dispatch({ type: types.GET_CHAPTER.REQUEST });
        axios
            .get(`${BASE_URL}?method=getChapters`, {
            })
            .then(res => {
                dispatch({
                    type: types.GET_CHAPTER.SUCCESS, payload: {
                        data: res.data,
                        msg: 'get surevey questions success'
                    }
                });
            })
            .catch(err => {
                dispatch({ type: types.GET_CHAPTER.FAILED, payload: { err } });
            });
    };
};



export const selectQuestion = id => {
    return { type: types.SELECT_QUESTION, payload: { id } }
};

export const nextQuestion = () => {
    return { type: types.NEXT_QUESTION, payload: null }
}

export const prevQuestion = () => {
    return { type: types.PREVIOUS_QUESTION, payload: null }
}

export const addAnswer = (obj) => {
    return { type: types.ADD_ANSWER, payload: { answer: obj } }
}

export const saveAnswer = index => {
    return { type: types.SAVE_ANSWER, payload: { index } }
}

export const clearAnswer = index => {
    return { type: types.CLEAR_ANSWER, payload: {} }
}

export const deSelect = () => {
    return { type: types.DESELECT, payload: null }
}

export const postAnswer = (answers, userid, examType, chapterId, attempt) => {
    let devMode = localStorage.getItem('devMode');
    // JSON.parse(devMode) ? 1 :
    let URL = `?method=insertAnswers&userid=${userid}&answers=${JSON.stringify(answers)}&type=${examType}&attempt=${examType === 'F' ? attempt + 1 : attempt}`;
    if (examType === 'C') {
        URL = `${URL}&chapterid=${chapterId}`;
    }
    return dispatch => {
        dispatch({ type: types.POST_ANSWERS.REQUEST });
        axios
            .get(`${BASE_URL}${URL}`)
            .then(res => {
                console.log('response', res);

                dispatch({
                    type: types.POST_ANSWERS.SUCCESS, payload: {
                        data: res.data.AnswerKey,
                        testResultMark: res.data.Mark, 
                        msg: 'get surevey questions success'
                    }
                });
            })
            .catch(err => {
                dispatch({ type: types.POST_ANSWERS.FAILED, payload: { err } });
            });
    };
}

export const authenticate = (data) => dispatch => {
    dispatch({ type: types.AUTHENTICATE.REQUEST })
    axios.get(`${BASE_URL}?method=logUser&email=${data.userName}&password=${data.password}`)
        .then(res => {
            dispatch({ type: types.AUTHENTICATE.SUCCESS, payload: res.data })
        }).catch(err => {
            dispatch({ type: types.AUTHENTICATE.FAILED })
        })
}

export const resetToken = () => {
    return { type: types.LOGOUT }
}

export const setExamType = obj => {
    return { type: types.SET_EXAM_TYPE, payload: obj }

}

export const decrementPendingAttempt = (sessionData, examType, chapterId) => dispatch => {
    let obj = sessionData
    if (examType === 'F') {
        obj.PENDINGATTEMPTSFORFULLPORTION = obj.PENDINGATTEMPTSFORFULLPORTION - 1;
    } else {
        obj.COMPLETEDATTEMPTSFORCHAPTERS = obj.COMPLETEDATTEMPTSFORCHAPTERS + 1;
        // let isIdFound = false;
        // obj.PENDINGATTEMPTSFORCHAPTERS.map(obj => {
        //     if (obj.CHAPTERID === chapterId) {
        //         isIdFound = true;
        //         obj.PENDING_ATTEMPT = obj.PENDING_ATTEMPT - 1;
        //     }
        //     return obj;
        // });
        // if (!isIdFound) {
        //     obj.PENDINGATTEMPTSFORCHAPTERS.push({ CHAPTERID: chapterId, PENDING_ATTEMPT: obj.CHAPTERWISEATTEMPTS - 1 })
        // }
    }
    localStorage.setItem('session', JSON.stringify(obj));
    dispatch({ type: types.AUTHENTICATE.SUCCESS, payload: obj })
}

export const getPreviousAttempt = userId => dispatch => {
    dispatch({ type: types.GET_PREVIOUS_ATTEMPT.REQUEST })
    axios.get(`${BASE_URL}?method=getExamHistory&userid=${userId}`)
        .then(res => {
            dispatch({ type: types.GET_PREVIOUS_ATTEMPT.SUCCESS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: types.GET_PREVIOUS_ATTEMPT.FAILED })

        })
}

export const setDefalutDashBoard = () => {
    let val = Math.floor(1000 + Math.random() * 9000);
    return { type: types.SET_DEFAULT_DASHBOARD, payload: val }
}

export const clearStore = () => {
    return { type: types.CLEAR_STORE_EXCEPT_SESSION, payload: {} }
}