import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import { CircularProgress } from '@material-ui/core';
import { authenticate, resetToken } from '../service/question/action';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        TSR Chemistry
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    flexGrow: 1,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalCreateGroup: false,
      userName: '',
      password: '',
      invalidUser: false,
      invalidMsg: ''
    };
  }

  componentDidMount() {
    this.props.dispatch(resetToken());
    localStorage.removeItem('authToken');
    localStorage.removeItem('session');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_isEqual(this.props.sessionDetails, prevProps.sessionDetails) && !_isEmpty(this.props.sessionDetails)) {
      this.setState({ invalidUser: false, invalidMsg: '' })
      if (this.props.sessionDetails.SUCCESS === 'true') {

        localStorage.setItem('authToken', true);
        console.log('setting accessToken')
        localStorage.setItem('session', JSON.stringify(this.props.sessionDetails));
        console.log('redirect to dashboard')
        this.props.history.push('/dashboard');
      } else {
        this.setState({ invalidMsg: 'Invalid User', invalidUser: true })
      }
    }
  }

  login = () => {
    const { userName, password } = this.state;
    if (userName.length === 0 || password.length === 0) {
      this.setState({ invalidUser: true, invalidMsg: 'Please enter Email and Password' });
    } else {
      let param = {
        userName: userName,
        password: password
      }
      this.props.dispatch(authenticate(param))
    }

  }

  render() {
    const classes = useStyles;

    return (
      <div>
        <AppBar  position="absolute">
          <Toolbar>
            <Typography style={{alignItems: "center" }} variant="h6" className={classes.title} color="inherit" noWrap >
              TSR Chemistry
            </Typography>
          </Toolbar>
        </AppBar>
        <br/>
        <br/>
        <br/>
        <br/>
        <Container className="mt-5"component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper} style={{minHeight:'80vh'}}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
          </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => this.setState({ userName: e.target.value })}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => this.setState({ password: e.target.value })}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              {this.props.sessionLoading ?
                <div style={{ marginTop: '5%', textAlign: 'center' }}>
                  <CircularProgress />
                </div>
                : <Button
                  fullWidth
                  onClick={this.login}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign In
            </Button>
              }
              {this.state.invalidUser &&
                <div style={{ color: 'red', fontSize: 16, textAlign: 'center', padding: '5%' }}>
                  {this.state.invalidMsg}
                </div>
              }
            </form>
          </div>
          <Box mt={1}>
            <Copyright />
          </Box>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    sessionLoading: state.chapterReducer.sessionLoading,
    sessionDetails: state.chapterReducer.sessionDetails
  }
}

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);