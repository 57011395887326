import React, { Component } from 'react';
import { Radio } from 'antd';
import { addAnswer } from '../service/question/action';
import { connect } from 'react-redux';

var HtmlToReactParser = require('html-to-react').Parser;

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            optionAlpha: [ "a)", "b)", "c)", "d)", "e)", "f)", "g)" ]
        }
        this.deSelect = this.deSelect.bind(this);
    }

    componentDidMount() {
        this.props.setClick(this.deSelect);
    }

    onChange = (e) => {
        const { addAnswer, surveyQuestion } = this.props;
        this.setState({ value: e.target.value });
        addAnswer({ id: surveyQuestion.ID, option: e.target.value })
    }

    deSelect = () => {
        this.setState({ value: null });
    }

    componentDidUpdate(prevProps) {
        if ((this.props.surveyQuestion && prevProps.surveyQuestion) && (prevProps.surveyQuestion.question !== this.props.surveyQuestion.question)) {
            this.setState({ value: null });
        }
    }
    render() {
        const radioStyle = {
            height: 'auto',
            lineHeight: '30px', marginTop: 10, marginLeft:5
        };
        const { surveyQuestion, answers } = this.props;
        var htmlInput = surveyQuestion ? surveyQuestion.QUESTION : '';
        var htmlToReactParser = new HtmlToReactParser();
        var reactElement = htmlToReactParser.parse(htmlInput);
        return (
            surveyQuestion ?
                <div style={{ backgroundColor: 'white', padding: 30 }}>
                    <h6>{reactElement}</h6>
                    <Radio.Group onChange={this.onChange}
                        value={this.state.value && answers[surveyQuestion.ID] ? answers[surveyQuestion.ID].option : null} >
                        {
                            surveyQuestion.OPTIONS.map((option, index) => {
                                var reactOptionElement = htmlToReactParser.parse(option.VALUE);
                                
                                return <div key={index} style={{display:'block'}}>{this.state.optionAlpha[index]} <Radio  key={index} style={radioStyle} value={option}>
                                   <span style={{ color: 'black', fontSize: '15px' }}>{reactOptionElement}</span>
                                </Radio> </div>
                            })
                        }
                    </Radio.Group>
                    {/* {
                            surveyQuestion.OPTIONS.map((option, index) => {
                                var reactOptionElement = htmlToReactParser.parse(option.VALUE);
                                return <span style={{ color: 'black',fontSize: '15px' }}>{reactOptionElement}</span>
                            })
                        } */}
                </div> : ''
        )
    }
}


const mapStateToProps = (state) => {
    return {
        answers: state.chapterReducer.answers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addAnswer: (obj) => dispatch(addAnswer(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);