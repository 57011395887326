import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ExamIcon from '@material-ui/icons/Create';
import { setDefalutDashBoard, clearStore, getChapters } from '../service/question/action';

export const MainListItems = (props) => {
  return (
    <div>
      <ListItem button onClick={() => {
        props.dispatch(setDefalutDashBoard());
        props.dispatch(clearStore());
        props.dispatch(getChapters());
        props.history.push('/dashboard')
      }}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText

          primary="Dashboard" />
      </ListItem>
      <ListItem button onClick={() => {
        props.dispatch(clearStore());
        props.history.push('/history')
      }}>
        <ListItemIcon>
          <AssignmentTurnedInIcon />
        </ListItemIcon>
        <ListItemText
          primary="Previous Mark" />
      </ListItem>
      <ListItem button onClick={() => {
        props.dispatch(clearStore());
        props.dispatch(getChapters());
        props.history.push('/dashboard')
      }}>
        <ListItemIcon>
          <ExamIcon />
        </ListItemIcon>
        <ListItemText primary="Attempt" />
      </ListItem>
    </div>
  );
}


